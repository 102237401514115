import {Chip} from "@mui/material";
import * as React from "react";

type VideoStatusesType = {
    [status: number]: {
        label: React.ReactNode,
        color?: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    }
}

export enum VideoStatusId {
    NEW = 0,
    DEEPMOTION_PROCESS = 10,
    DEEPMOTION_FAIL = 18,
    DEEPMOTION_SUCCESS = 19,
    ALGO_INPUT_REQUIRED = 20,
    ALGO_SCHEDULED = 21,
    ALGO_FAIL = 28,
    ALGO_SUCCESS = 29,
}

const VideoStatuses:VideoStatusesType = {
    [VideoStatusId.NEW]: {label: "New", color: "info"},
    [VideoStatusId.DEEPMOTION_PROCESS]: {label: "Deepmotion Processing", color: "info"},
    [VideoStatusId.DEEPMOTION_FAIL]: {label: "Deepmotion Process Failed", color: "error"},
    [VideoStatusId.DEEPMOTION_SUCCESS]: {label: "Deepmotion Process Succeeded", color: "success"},
    [VideoStatusId.ALGO_INPUT_REQUIRED]: {label: "Manual Input For Algorithm is Required", color: "info"},
    [VideoStatusId.ALGO_SCHEDULED]: {label: "Scheduled For Algorithm", color: "info"},
    [VideoStatusId.ALGO_FAIL]: {label: "Algorithm Process Failed", color: "error"},
    [VideoStatusId.ALGO_SUCCESS]: {label: "Algorithm Process Succeeded", color: "success"},
}

interface VideoStatusProps {
    status:number;
}

export const VideoStatus = ({status}:VideoStatusProps) => {
    return(
        <Chip {...VideoStatuses[status]} />
    )
}
