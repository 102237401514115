import {RootState} from "../redux/store";

export const getAuthorizationHeader = (getState:()=>RootState):{Authorization: string} => {
    const {auth} = getState();
    const token = auth?.token;

    let headers = {
        'Authorization': `Token ${token}`
    };

    return headers;
}