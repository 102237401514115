import React, {useEffect, useState} from "react";
import {DataGrid, GridColDef, GridRenderCellParams, GridSelectionModel} from "@mui/x-data-grid";
import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import {FailCallbackType, SuccessCallbackType} from "../api/types";
import prettyBytes from "pretty-bytes";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {VideoPayload} from "../api/payload/core";
import {useDispatch} from "react-redux";
import {getVideoListAction} from "../api/action/core";
import {VideoStatus} from "../components/VideoStatus";
import {Download} from "@mui/icons-material";

const columns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'ID',
        flex: 1
    },
    {
        field: 'filename',
        headerName: 'Video File Name',
        flex: 10,
        editable: false,
        renderCell: (params) => (<Link to={`/videos/${params.row.id}`}>{params.value}</Link>)
    },
    {
        field: 'filesize',
        headerName: 'File Size',
        type: "number",
        align: "left",
        headerAlign: "left",
        flex: 2,
        editable: false,
        renderCell: (params:GridRenderCellParams<number>) => (prettyBytes(params.value))
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        type: "dateTime",
        flex: 3,
        editable: false,
        renderCell: (params:GridRenderCellParams<Date>) => (<Moment format={"L LTS"} date={params.value} />),
    },
    {
        field: 'updated_at',
        headerName: 'Updated At',
        type: "dateTime",
        flex: 3,
        editable: false,
        renderCell: (params:GridRenderCellParams<Date>) => (<Moment format={"L LTS"} date={params.value} />),
    },
    {
        field: 'status',
        headerName: 'Status',
        type: "string",
        flex: 5,
        editable: false,
        renderCell: (params:GridRenderCellParams<number>) => (<VideoStatus status={params.value} />),
    },
];

export const VideoList = () => {
    const [videos, setVideos] = useState<VideoPayload[]>([]);
    const [pageSize, setPageSize] = useState<number>(100);
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);

    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getVideoListAction({page:page, page_size: pageSize}, {success: onTableDataGet, fail: onRequestFail}));
    }, [pageSize, page, dispatch]);

    const onTableDataGet:SuccessCallbackType = response => {
        const {data} = response;
        setCount(data.count);
        setVideos(data.results);
    };

    const onRequestFail:FailCallbackType = reason => {

    };

    return(
        <>
            <Box sx={{m: 2}}>
                <Stack direction={"row"} spacing={5}>
                    <Typography variant={"h4"}>
                        Videos
                    </Typography>
                    <Button variant={"outlined"} disabled={selectionModel.length === 0} onClick={()=>{alert("still being developed")}}><Download /> Export Selected</Button>
                </Stack>
            </Box>
            <Box sx={{m: 2}}>
                <div style={{ height: "calc(100vh - 154px)", minHeight: "500px", width: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid
                                rows={videos}
                                columns={columns}
                                paginationMode={"server"}
                                rowCount={count}
                                pageSize={pageSize}
                                rowsPerPageOptions={[10, 25, 50, 100]}
                                onPageChange={(page) => {setPage(page+1)}}
                                onPageSizeChange={(pageSize) => {setPageSize(pageSize)}}
                                disableSelectionOnClick
                                checkboxSelection
                                selectionModel={selectionModel}
                                onSelectionModelChange={(selectionModel)=>{
                                    setSelectionModel(selectionModel);
                                    console.log(selectionModel);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Box>
        </>
    )
}
