import React, {useEffect} from "react";
import {Header} from "../../components/Header";
import {useDispatch, useSelector} from "react-redux";
import {AuthStatus, IAuthState} from "../../redux/types";
import {Public} from "./Public";
import {Private} from "./Private";
import {RootState} from "../../redux/store";
import {userProfileAction} from "../../api/action/auth";

import "../../style/plain/common.css"

export const Common = () => {
    const auth = useSelector((state:RootState):IAuthState => state.auth as IAuthState);
    const dispatch = useDispatch();

    useEffect(()=>{
        const token = localStorage.getItem("token");

        if(token != null){
            dispatch(userProfileAction(token));
        }
    }, [dispatch]);

    return(
        <>
            <Header/>
            {
                auth.status === AuthStatus.VALID ?
                    <Private/>
                    :
                    <Public/>
            }
        </>

    )
}