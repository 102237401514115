"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddRows = void 0;
const react_1 = __importStar(require("react"));
const AddRows = ({ addRows }) => {
    const [value, setValue] = react_1.useState(1);
    const [rawValue, setRawValue] = react_1.useState(String(value));
    return (react_1.default.createElement("div", { className: "dsg-add-row" },
        react_1.default.createElement("button", { type: "button", className: "dsg-add-row-btn", onClick: () => addRows(value) }, "Add"),
        ' ',
        react_1.default.createElement("input", { className: "dsg-add-row-input", value: rawValue, onBlur: () => setRawValue(String(value)), type: "number", min: 1, onChange: (e) => {
                setRawValue(e.target.value);
                setValue(Math.max(1, Math.round(parseInt(e.target.value) || 0)));
            }, onKeyPress: (event) => {
                if (event.key === 'Enter') {
                    addRows(value);
                }
            } }),
        ' ',
        "rows"));
};
exports.AddRows = AddRows;
