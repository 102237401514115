import {AuthPayload} from "../api/payload/auth";
import {AuthActionType} from "./action_types";
import {AnyAction} from "@reduxjs/toolkit";

export interface IAuthAction extends AnyAction{
    type: AuthActionType,
    payload?: AuthPayload
}

export enum AuthStatus {
    ANONYMOUS="ANONYMOUS",
    PROCESSING="PROCESSING",
    VALID="VALID"
}

export interface IAuthState {
    status: AuthStatus,
    token?: string,
    info?: {
        username?: string,
        first_name?: string,
        last_name?: string
    }
}