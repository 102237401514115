import {createTheme} from "@mui/material";
import {indigo, lightBlue} from "@mui/material/colors";

export const theme = createTheme({
    palette: {
        primary: {
            main: indigo[500],
            dark: indigo[700],
            light: indigo[300],
        },
        secondary: {
            main: lightBlue[500],
            dark: lightBlue[700],
            light: lightBlue[300],
        }
    },


});

