import React from "react";
import {Login} from "../Login";
import {Route, Routes} from "react-router-dom";


export const Public = () => {
    return (
        <Routes>
            <Route path={"/"} element={<Login />} />
            <Route path={"*"} element={<Login />} />
        </Routes>
    );
}