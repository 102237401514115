import React, {FormEvent, useState} from "react";
import {Alert, Button, Paper, Stack, TextField} from "@mui/material";
import {AuthRequest} from "../api/payload/auth";
import {AxiosError} from "axios";
import {loginAction} from "../api/action/auth";
import {useDispatch} from "react-redux";
import {Delete as DeleteIcon, Login as LoginIcon} from "@mui/icons-material";

const initialLoginData:AuthRequest = {username:"user", password:"superscreetpass"};

export const Login = () => {
    const [loginData, setLoginData] = useState<AuthRequest>(initialLoginData);
    const [errors, setErrors] = useState<{[key:string]: string[]}>();
    const dispatch = useDispatch();

    const inputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = event.target;
        setLoginData({
            ...loginData,
            [id]: value
        });
    }

    const submitForm = (event: FormEvent) => {
        dispatch(loginAction(loginData, {fail: onFail}));

        event.preventDefault();
    }

    const onFail = (error:AxiosError) => {
        const {response} = error;

        if(response) {
            setErrors(response.data);
            console.log(response.data);
        } else {
            setErrors({non_field_errors: ["A Network Error Occured!"]});
        }
    }

    const cleanForm = () => {
        setLoginData(initialLoginData);
        setErrors({});
    }

    return (
        <>
            <Paper sx={{width: "600px", padding: "0.1em 2em 2em 2em", marginTop: "2em", marginLeft: "auto", marginRight: "auto"}}>
                <h1>Speedworks App Login</h1>
                <form onSubmit={submitForm}>
                    <Stack spacing={2}>
                        <TextField
                            id={"username"}
                            label="Username"
                            autoComplete="current-username"
                            required
                            value={loginData.username}
                            onInput={inputChange}
                        />
                        <TextField
                            id={"password"}
                            label="Password"
                            type="password"
                            autoComplete="current-password"
                            required
                            value={loginData.password}
                            onInput={inputChange}
                        />
                        <Stack direction={"column"} sx={{display: "flex"}} spacing={2} >

                            <Button
                                type="submit"
                                variant={"outlined"}
                                startIcon={<LoginIcon />}
                                sx={{flexGrow: 1}}
                            >
                                Login
                            </Button>

                            <Button
                                variant={"outlined"}
                                sx={{flexGrow: 1}}
                                startIcon={<DeleteIcon />}
                                color={"error"}
                                onClick={cleanForm}
                            >

                                Clean Form
                            </Button>
                        </Stack>

                        {
                            errors &&
                                <Stack direction={"column"}>
                                    {
                                        Object.entries(errors).map(([key, value]) => {
                                            let children:any = [];
                                            console.log(key, value);
                                            value.forEach((msg)=>{
                                                children.push(
                                                    <Alert severity={"error"}>{msg}</Alert>
                                                );
                                            });
                                            return children;
                                        })
                                    }
                                </Stack>
                        }
                    </Stack>
                </form>
            </Paper>
        </>
    )
}