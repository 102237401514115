import {AuthStatus, IAuthAction, IAuthState} from "../types";
import {AuthActionType} from "../action_types";
import {Reducer} from "@reduxjs/toolkit";

const initialState:IAuthState = {
    status: AuthStatus.ANONYMOUS
}

export const authReducer:Reducer<IAuthState|undefined, IAuthAction> = (state= initialState, action)=>{
    switch (action.type){
        case AuthActionType.PROCESSING:
            return {
                ...state,
                status: AuthStatus.PROCESSING
            };
        case AuthActionType.LOGIN:
            if (action.payload === undefined) {
                break;
            }

            const {token, username, first_name, last_name} = action.payload;
            localStorage.setItem("token", token);

            return {
                ...state,
                status: AuthStatus.VALID,
                token: token,
                info: {
                    username: username,
                    first_name: first_name,
                    last_name: last_name
                }
            };
        case AuthActionType.LOGOUT:
            localStorage.clear();

            return initialState;
        default:
            return state;
    }
}