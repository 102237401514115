"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFirstRender = void 0;
const react_1 = require("react");
const useFirstRender = () => {
    const firstRenderRef = react_1.useRef(true);
    const firstRender = firstRenderRef.current;
    firstRenderRef.current = false;
    return firstRender;
};
exports.useFirstRender = useFirstRender;
