import {AuthPayload, AuthRequest} from "../payload/auth";
import {URL_API_LOGIN, URL_API_PROFILE} from "../urls";
import axios, {AxiosResponse} from "axios";

export const loginService = ({username, password}:AuthRequest):Promise<AxiosResponse<AuthPayload>> => {
    const url = URL_API_LOGIN;
    const data = {username: username, password: password}
    return new Promise(((resolve, reject) => {
        axios.post(url, data)
            .then(
                (response: AxiosResponse<AuthPayload>) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                }
            );
    }));
}

export const userProfileService = (token:string):Promise<AxiosResponse<AuthPayload>> => {
    const url = URL_API_PROFILE;
    const config = {headers: {Authorization: `Token ${token}`}};
    return new Promise(((resolve, reject) => {
        axios.get(url, config)
            .then(
                (response: AxiosResponse<AuthPayload>) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                }
            );
    }));
}