import React from "react";

import {AppBar, Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar} from "@mui/material";

import {AccountCircle, Menu as MenuIcon, SlowMotionVideo, UploadRounded} from "@mui/icons-material";

import logo from "../assets/img/speedworks.png";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {AuthStatus, IAuthState} from "../redux/types";

export const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const auth = useSelector((state:RootState):IAuthState => state.auth as IAuthState);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return(
        <AppBar position={"static"}>
            <Toolbar>
                <Box sx={{ ml: 0, mr: 2, height: "3em", maxHeight: "inherit", display: 'flex' }}>
                    <img src={logo} alt="SpeedWorks" />
                </Box>

                <Box sx={{flexGrow: 1}}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: 'block',
                        }}
                    >
                        <MenuItem component={Link} to={"/videos/"}>
                            <ListItemIcon>
                                <SlowMotionVideo />
                            </ListItemIcon>
                            <ListItemText>
                                Videos
                            </ListItemText>
                        </MenuItem>
                        <MenuItem component={Link} to={"/"}>
                            <ListItemIcon>
                                <UploadRounded />
                            </ListItemIcon>
                            <ListItemText>
                                Upload
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                </Box>
                {
                    auth.status === AuthStatus.VALID &&
                    <Box>
                        <Button
                            sx={{
                                backgroundColor: 'secondary.main',
                                '&:hover': {
                                    backgroundColor: 'secondary.dark'
                                }
                            }}
                            variant={"contained"}
                            endIcon={<AccountCircle/>}
                        >
                            {auth.info?.username || "user" }
                        </Button>
                    </Box>
                }

            </Toolbar>
        </AppBar>
    )
}

