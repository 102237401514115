import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {URL_API_CORE_VIDEO} from "../urls";
import {CallbackServiceProp, ProgressCallbackType, PagedServiceProp, HttpHeaders} from "../types";
import {UploadVideoRequest} from "../payload/core";

export const uploadVideoService = (data:UploadVideoRequest, progress:ProgressCallbackType, extraHeaders:HttpHeaders):Promise<AxiosResponse> => {
    const url = URL_API_CORE_VIDEO({});

    const formData = new FormData();
    formData.append('file', data.file);

    const config:AxiosRequestConfig = {
        headers: {
            'content-type': 'multipart/form-data',
            ...extraHeaders
        },
        onUploadProgress: progress,
    }

    return new Promise(((resolve, reject) => {
        axios.post(url, formData, config)
            .then(
                (response: AxiosResponse) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                }
            );
    }));
}

export const getVideoListService = ({page=1, page_size=10}:PagedServiceProp, extraHeaders:HttpHeaders):Promise<AxiosResponse> => {
    let query = {
        page: page,
        page_size: page_size
    }

    let url = URL_API_CORE_VIDEO({params: query});

    const config:AxiosRequestConfig = {
        headers: {
            ...extraHeaders
        }
    }

    return new Promise(((resolve, reject) => {
        axios.get(url, config)
            .then(
                (response: AxiosResponse) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                }
            );
    }));
}

export const getVideoService = (id: number, extraHeaders:HttpHeaders):Promise<AxiosResponse>  => {
    let url = URL_API_CORE_VIDEO({id: id});

    const config:AxiosRequestConfig = {
        headers: {
            ...extraHeaders
        }
    }

    return new Promise(((resolve, reject) => {
        axios.get(url, config)
            .then(
                (response: AxiosResponse) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                }
            );
    }));

}