import {CallbackServiceProp, PagedServiceProp, ProgressCallbackType} from "../types";
import {Dispatch} from "@reduxjs/toolkit";
import {IAuthAction} from "../../redux/types";
import * as coreServices from "../service/core";
import {RootState} from "../../redux/store";
import {UploadVideoRequest} from "../payload/core";
import {getAuthorizationHeader} from "../utils";


type UploadVideoCallbacksType = CallbackServiceProp & {
    progress:ProgressCallbackType
}


export const uploadVideoAction = (data:UploadVideoRequest, callbacks:UploadVideoCallbacksType) => {
    const {success, fail, progress} = callbacks;
    return async (dispatch: Dispatch<IAuthAction>, getState: () => RootState) => {
        coreServices.uploadVideoService(
            data,
            progress,
            getAuthorizationHeader(getState)
        ).then(success, fail);
    }
}

export const getVideoListAction = ({page=1, page_size=10}: PagedServiceProp, callbacks:CallbackServiceProp) => {
    let query = {
        page: page,
        page_size: page_size
    }

    const {success, fail} = callbacks;

    return async (dispatch: Dispatch<IAuthAction>, getState: () => RootState) => {
        coreServices.getVideoListService(
            query,
            getAuthorizationHeader(getState)
        ).then(success, fail);
    }
}

export const getVideoAction = (id: number, callbacks:CallbackServiceProp) => {
    const {success, fail} = callbacks;

    return async (dispatch: Dispatch<IAuthAction>, getState: () => RootState) => {
        coreServices.getVideoService(
            id,
            getAuthorizationHeader(getState)
        ).then(success, fail);
    }
}
