"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColumns = void 0;
const react_1 = __importStar(require("react"));
const defaultComponent = () => react_1.default.createElement(react_1.default.Fragment, null);
const defaultIsCellEmpty = () => false;
const identityRow = ({ rowData }) => rowData;
const defaultCopyValue = () => null;
const defaultGutterComponent = ({ rowIndex }) => (react_1.default.createElement(react_1.default.Fragment, null, rowIndex + 1));
const cellAlwaysEmpty = () => true;
const defaultPrePasteValues = (values) => values;
const useColumns = (columns, gutterColumn, stickyRightColumn) => {
    return react_1.useMemo(() => {
        var _a, _b, _c, _d, _e, _f;
        const partialColumns = [
            gutterColumn === false
                ? {
                    width: 0,
                    minWidth: 0,
                    // eslint-disable-next-line react/display-name
                    component: () => react_1.default.createElement(react_1.default.Fragment, null),
                    headerClassName: 'dsg-hidden-cell',
                    cellClassName: 'dsg-hidden-cell',
                    isCellEmpty: cellAlwaysEmpty,
                }
                : Object.assign(Object.assign({}, gutterColumn), { width: (_a = gutterColumn === null || gutterColumn === void 0 ? void 0 : gutterColumn.width) !== null && _a !== void 0 ? _a : '0 0 40px', minWidth: (_b = gutterColumn === null || gutterColumn === void 0 ? void 0 : gutterColumn.minWidth) !== null && _b !== void 0 ? _b : 0, title: (_c = gutterColumn === null || gutterColumn === void 0 ? void 0 : gutterColumn.title) !== null && _c !== void 0 ? _c : (react_1.default.createElement("div", { className: "dsg-corner-indicator" })), component: (_d = gutterColumn === null || gutterColumn === void 0 ? void 0 : gutterColumn.component) !== null && _d !== void 0 ? _d : defaultGutterComponent, isCellEmpty: cellAlwaysEmpty }),
            ...columns,
        ];
        if (stickyRightColumn) {
            partialColumns.push(Object.assign(Object.assign({}, stickyRightColumn), { width: (_e = stickyRightColumn.width) !== null && _e !== void 0 ? _e : '0 0 40px', minWidth: (_f = stickyRightColumn.minWidth) !== null && _f !== void 0 ? _f : 0, isCellEmpty: cellAlwaysEmpty }));
        }
        return partialColumns.map((column) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            return (Object.assign(Object.assign({}, column), { width: (_a = column.width) !== null && _a !== void 0 ? _a : 1, minWidth: (_b = column.minWidth) !== null && _b !== void 0 ? _b : 100, renderWhenScrolling: (_c = column.renderWhenScrolling) !== null && _c !== void 0 ? _c : true, component: (_d = column.component) !== null && _d !== void 0 ? _d : defaultComponent, disableKeys: (_e = column.disableKeys) !== null && _e !== void 0 ? _e : false, disabled: (_f = column.disabled) !== null && _f !== void 0 ? _f : false, keepFocus: (_g = column.keepFocus) !== null && _g !== void 0 ? _g : false, deleteValue: (_h = column.deleteValue) !== null && _h !== void 0 ? _h : identityRow, copyValue: (_j = column.copyValue) !== null && _j !== void 0 ? _j : defaultCopyValue, pasteValue: (_k = column.pasteValue) !== null && _k !== void 0 ? _k : identityRow, prePasteValues: (_l = column.prePasteValues) !== null && _l !== void 0 ? _l : defaultPrePasteValues, isCellEmpty: (_m = column.isCellEmpty) !== null && _m !== void 0 ? _m : defaultIsCellEmpty }));
        });
    }, [gutterColumn, stickyRightColumn, columns]);
};
exports.useColumns = useColumns;
