import {Box, Table, TableCell, TableRow, Typography} from "@mui/material";
import {useState} from "react";
import {ResultGrid} from "./ResultGrid";

interface AlgoResultsProps {
    params: string | null;
    avg: string | null;
    curves: string | null;
    message: string | null;
}


export const AlgoResults = ({params, avg, curves, message}:AlgoResultsProps) =>{

    return (
        <Box sx={{p: ".5em", minHeight: "500px", border: "1px solid", borderColor: "grey.300"}}>
            {
                params &&
                    <>
                        <Typography variant={"h5"} >Results</Typography>
                        <ResultGrid initial={params} />
                    </>
            }
            {
                avg &&
                <>
                    <Typography variant={"h5"} >Average</Typography>
                    <ResultGrid initial={avg} />
                </>
            }
            {
                (!params && message) &&
                <Typography variant={"body1"}>
                    {`${message}`}
                </Typography>
            }
        </Box>
    )
}