const urljoin = require("url-join");

const paramHandler = (params:{[key:string]: string|number}):string => {
    let result:string[] = [];

    Object.entries(params).forEach(([key, value]) => {
        let param = `${encodeURI(key)}=${encodeURI(value.toString())}`;
        result.push(param);
    })

    if(result.length > 0)
        return `${result.join('&')}`

    return "";
}

const URL_BASE = "/";
const URL_API_BASE = urljoin(URL_BASE, "api");
//const URL_API_BASE = URL_BASE;

export const URL_API_LOGIN = urljoin(URL_API_BASE, "login/");
export const URL_API_PROFILE = urljoin(URL_API_BASE, "profile/");

const URL_API_CORE = urljoin(URL_API_BASE, "core");
export const URL_API_CORE_VIDEO = (props:{id?: number, params?:{[key:string]: string|number}}):string => {
    const {id, params} = props;

    let url:string = urljoin(URL_API_CORE, "video/");

    if(id !== undefined){
        url = urljoin(url, id.toString(), '/');
    }

    if(params !== undefined){
        const urlParams = paramHandler(params);
        url = `${url}?${urlParams}`;
    }

    return url;
}

const URL_API_ALGO = urljoin(URL_API_BASE, "algo");
export const URL_API_ALGO_PROCESS = (props:{id:number}):string => {
    const {id} = props;

    let url:string = urljoin(URL_API_ALGO, id.toString(), "/");

    return url;
}
