import React, {FormEvent, useState} from "react";
import {Box, Button, Stack, TextField} from "@mui/material";
import {AlgoProcessRequest} from "../api/payload/algo";

interface FormInputType {
    legLength: number | null;
    distance: number | null;
    fps: number;
}

interface AlgoProcessAttributeFormProps extends FormInputType{
    onSave: (data:AlgoProcessRequest) => void;
    onSchedule: () => void;
    scheduleButtonHidden: boolean;
}

export const AlgoProcessAttributeForm = ({legLength=0, distance=10, fps=240, onSave, onSchedule, scheduleButtonHidden=false}: AlgoProcessAttributeFormProps) =>{
    const [formInput, setFormInput] = useState<FormInputType>({
        legLength: legLength,
        distance: distance,
        fps: fps,
    });

    const onFormSubmit = (event:FormEvent) => {
        event.preventDefault();
        onSave({
            leg_length: formInput.legLength as AlgoProcessRequest['leg_length'],
            distance: formInput.distance as AlgoProcessRequest['distance'],
            fps: formInput.fps
        });
    }

    const onInputChange = (event:React.ChangeEvent<HTMLInputElement>) => {
        const {id, value} = event.target;
        setFormInput({
            ...formInput,
            [id]: value
        })
    }

    return (
        <Box sx={{p: ".5em", minHeight: "500px", border: "1px solid", borderColor: "grey.300"}}>
            <form onSubmit={onFormSubmit}>
                <Stack direction={"column"} spacing={2}>
                    <TextField
                        id={"legLength"}
                        label={"Leg Length"}
                        type={"text"}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*(\.[0-9]+|)' }}
                        required
                        onInput={onInputChange}

                        value={formInput.legLength}
                    />
                    <TextField
                        id={"distance"}
                        label={"Distance"}
                        type={"text"}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*(\.[0-9]+|)' }} required
                        onInput={onInputChange}
                        value={formInput.distance} />
                    <TextField
                        id={"fps"}
                        label={"FPS"}
                        type={"number"}
                        required
                        onInput={onInputChange}
                        value={formInput.fps} />
                    <Stack direction={"row"}>
                        <Button variant={"contained"} color={"primary"} type={"submit"}>Save</Button>
                        {
                            !scheduleButtonHidden &&
                            <Button variant={"contained"} color={"error"} onClick={onSchedule}>Schedule</Button>
                        }

                    </Stack>
                </Stack>
            </form>
        </Box>
    )
}

