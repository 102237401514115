import React, {useEffect, useState} from "react";
import {
    DataSheetGrid,
    textColumn,
    keyColumn, Column,
} from 'react-datasheet-grid'

import 'react-datasheet-grid/dist/style.css'
import {Box, CircularProgress} from "@mui/material";

export interface ToeData {
    id?: number;
    sid?: number;
    name?: string;
    time?: number;
    sx?: number;
    sy?: number;
    ex?: number;
    ey?: number;
    ax?: number;
    ay?: number;
    p1x?: number;
    p1y?: number;
    p2x?: number;
    p2y?: number;
    p3x?: number;
    p3y?: number;
    p4x?: number;
    p4y?: number;
    p5x?: number;
    p5y?: number;
    p6x?: number;
    p6y?: number;
    p7x?: number;
    p7y?: number;
    p8x?: number;
    p8y?: number;
    p9x?: number;
    p9y?: number;
    p10x?: number;
    p10y?: number;
    p11x?: number;
    p11y?: number;
    p12x?: number;
    p12y?: number;
    p13x?: number;
    p13y?: number;
    p14x?: number;
    p14y?: number;
    p15x?: number;
    p15y?: number;
    p16x?: number;
    p16y?: number;
}

const getColumns = (disabled:boolean=false):Column[] => [
    { ...keyColumn("id", textColumn), title: "id", disabled: disabled},
    { ...keyColumn("sid", textColumn), title: "sid", disabled: disabled},
    { ...keyColumn("name", textColumn), title: "name", disabled: disabled},
    { ...keyColumn("time", textColumn), title: "time", disabled: disabled},
    { ...keyColumn("sx", textColumn), title: "sx", disabled: disabled},
    { ...keyColumn("sy", textColumn), title: "sy", disabled: disabled},
    { ...keyColumn("ex", textColumn), title: "ex", disabled: disabled},
    { ...keyColumn("ey", textColumn), title: "ey", disabled: disabled},
    { ...keyColumn("ax", textColumn), title: "ax", disabled: disabled},
    { ...keyColumn("ay", textColumn), title: "ay", disabled: disabled},
    { ...keyColumn("p1x", textColumn), title: "p1x", disabled: disabled},
    { ...keyColumn("p1y", textColumn), title: "p1y", disabled: disabled},
    { ...keyColumn("p2x", textColumn), title: "p2x", disabled: disabled},
    { ...keyColumn("p2y", textColumn), title: "p2y", disabled: disabled},
    { ...keyColumn("p3x", textColumn), title: "p3x", disabled: disabled},
    { ...keyColumn("p3y", textColumn), title: "p3y", disabled: disabled},
    { ...keyColumn("p4x", textColumn), title: "p4x", disabled: disabled},
    { ...keyColumn("p4y", textColumn), title: "p4y", disabled: disabled},
    { ...keyColumn("p5x", textColumn), title: "p5x", disabled: disabled},
    { ...keyColumn("p5y", textColumn), title: "p5y", disabled: disabled},
    { ...keyColumn("p6x", textColumn), title: "p6x", disabled: disabled},
    { ...keyColumn("p6y", textColumn), title: "p6y", disabled: disabled},
    { ...keyColumn("p7x", textColumn), title: "p7x", disabled: disabled},
    { ...keyColumn("p7y", textColumn), title: "p7y", disabled: disabled},
    { ...keyColumn("p8x", textColumn), title: "p8x", disabled: disabled},
    { ...keyColumn("p8y", textColumn), title: "p8y", disabled: disabled},
    { ...keyColumn("p9x", textColumn), title: "p9x", disabled: disabled},
    { ...keyColumn("p9y", textColumn), title: "p9y", disabled: disabled},
    { ...keyColumn("p10x", textColumn), title: "p10x", disabled: disabled},
    { ...keyColumn("p10y", textColumn), title: "p10y", disabled: disabled},
    { ...keyColumn("p11x", textColumn), title: "p11x", disabled: disabled},
    { ...keyColumn("p11y", textColumn), title: "p11y", disabled: disabled},
    { ...keyColumn("p12x", textColumn), title: "p12x", disabled: disabled},
    { ...keyColumn("p12y", textColumn), title: "p12y", disabled: disabled},
    { ...keyColumn("p13x", textColumn), title: "p13x", disabled: disabled},
    { ...keyColumn("p13y", textColumn), title: "p13y", disabled: disabled},
    { ...keyColumn("p14x", textColumn), title: "p14x", disabled: disabled},
    { ...keyColumn("p14y", textColumn), title: "p14y", disabled: disabled},
    { ...keyColumn("p15x", textColumn), title: "p15x", disabled: disabled},
    { ...keyColumn("p15y", textColumn), title: "p15y", disabled: disabled},
    { ...keyColumn("p16x", textColumn), title: "p16x", disabled: disabled},
    { ...keyColumn("p16y", textColumn), title: "p16y", disabled: disabled},
];



export interface ToeGridProps {
    defaultRowCount?: number;
    initial?:string;
    onChange?: (status:string, timeout: number) => void;
    onSave?: (csv:string)=> void;
    readonly?: boolean;
    saveAfter?: number;
}

export const ToeGrid = ({defaultRowCount=20, initial, onChange, onSave, readonly=false, saveAfter=2000}:ToeGridProps) => {
    const [opened, setOpened] = useState<boolean>(true);
    const [data, setData] = useState<ToeData[]>([]);
    const [timer, setTimer] = useState<NodeJS.Timeout>();

    const columns = getColumns(readonly);

    const setGridData = (gridData:ToeData[]) => {
        setData(gridData);
        if(!opened) {
            if(onChange){
                onChange("CHANGED", saveAfter);
            }

            if(timer){
                clearTimeout(timer);
            }

            let timeout = setTimeout(()=>{
                let csv:string = "";

                columns.forEach((column:Column, idx) => {
                    if(idx > 0){
                        csv += ';';
                    }
                    csv += column.id;
                });

                csv += '\n';

                gridData.forEach((row:ToeData) => {
                    if(row){
                        let lineArray:any[] = [];
                        columns.forEach((column:Column, idx) => {
                            if(column.id){
                                lineArray.push((row as any)[column.id]);
                            }
                        });
                        let lineStr = lineArray.join(";");
                        if(lineStr.length > lineStr.split(';').length - 1){
                            csv += lineStr + "\n";
                        }
                    }
                })

                if(onSave){
                    onSave(csv);
                }
            }, saveAfter);
            setTimer(timeout);
        } else {
            setOpened(false);
        }
    }

    useEffect(()=>{
        if(initial){
            let toeData:ToeData[] = [];
            initial.split('\n').forEach((line, idx)=>{
                if(idx > 0){
                    let toeDataLine:ToeData = {};
                    line.split(";").forEach((item, colIdx)=>{
                        let column = columns[colIdx];

                        (toeDataLine as any)[column.id || ""] = item.trim();
                    })
                    toeData.push(toeDataLine);
                }
            })
            setGridData(toeData);
        } else {
            setGridData(new Array(defaultRowCount).fill({}))
        }
    }, [])


    if(data === []){
        return (
            <Box sx={{border: "1px solid", borderColor: "grey.300", textAlign: "center"}}>
                <CircularProgress />
            </Box>
        )
    }

    return (
            <DataSheetGrid
                value={data}
                onChange={setGridData}
                columns={columns}
            />
    );

}


