import {AuthRequest} from "../payload/auth";
import {CallbackServiceProp} from "../types";
import {Dispatch} from "@reduxjs/toolkit";
import {IAuthAction} from "../../redux/types";
import {AuthActionType} from "../../redux/action_types";
import * as authServices from "../service/auth";
import {RootState} from "../../redux/store";

export const loginAction = (data:AuthRequest, callbacks?:CallbackServiceProp) => {
    const {success, fail} = callbacks || {};

    return async (dispatch: Dispatch<IAuthAction>, getState: () => RootState) => {
        dispatch({
            type: AuthActionType.PROCESSING
        });

        authServices.loginService(data)
            .then((response) =>{
                dispatch({
                    type: AuthActionType.LOGIN,
                    payload: response.data
                });
                if(success !== undefined){
                    success(response);
                }
            }, (error) => {
                dispatch({
                    type: AuthActionType.LOGOUT
                });
                if(fail !== undefined){
                    fail(error);
                }
            });
    }
}

export const userProfileAction = (token: string) => {
    return async (dispatch: Dispatch<IAuthAction>, getState: () => RootState) => {
        dispatch({
            type: AuthActionType.RESTORE
        });

        authServices.userProfileService(token)
            .then((response) =>{
                dispatch({
                    type: AuthActionType.LOGIN,
                    payload: response.data
                });
            }, (error) => {
                dispatch({
                    type: AuthActionType.LOGOUT
                });
            });
    }
}
