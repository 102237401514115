"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useColumnWidths = void 0;
const react_1 = require("react");
const useDeepEqualState_1 = require("./useDeepEqualState");
const useColumnWidths = (columns, width) => {
    const [columnWidths, setColumnWidths] = useDeepEqualState_1.useDeepEqualState(undefined);
    const [prevWidth, setPrevWidth] = react_1.useState(width);
    const { totalWidth, columnRights } = react_1.useMemo(() => {
        if (!columnWidths) {
            return { totalWidth: undefined, columnRights: undefined };
        }
        let total = 0;
        const columnRights = columnWidths.map((w, i) => {
            total += w;
            return i === columnWidths.length - 1 ? Infinity : total;
        });
        return {
            columnRights,
            totalWidth: total,
        };
    }, [columnWidths]);
    const columnsHash = columns
        .map(({ width, minWidth, maxWidth }) => [width, minWidth, maxWidth].join(','))
        .join('|');
    react_1.useEffect(() => {
        if (width === undefined) {
            return;
        }
        const el = document.createElement('div');
        el.style.display = 'flex';
        el.style.position = 'fixed';
        el.style.width = `${width}px`;
        el.style.left = '-999px';
        el.style.top = '-1px';
        const children = columns.map((column) => {
            const child = document.createElement('div');
            child.style.display = 'block';
            child.style.flex = String(column.width);
            child.style.minWidth = `${column.minWidth}px`;
            child.style.maxWidth = `${column.maxWidth}px`;
            return child;
        });
        children.forEach((child) => el.appendChild(child));
        document.body.insertBefore(el, null);
        setColumnWidths(children.map((child) => child.getBoundingClientRect().width));
        setPrevWidth(width);
        el.remove();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, columnsHash]);
    return {
        fullWidth: Math.abs((prevWidth !== null && prevWidth !== void 0 ? prevWidth : 0) - (totalWidth !== null && totalWidth !== void 0 ? totalWidth : 0)) < 0.1,
        columnWidths,
        columnRights,
        totalWidth,
    };
};
exports.useColumnWidths = useColumnWidths;
