import {getAlgoProcessService} from "../service/algo";
import {CallbackServiceProp, HttpHeaders} from "../types";
import {Dispatch} from "@reduxjs/toolkit";
import {IAuthAction} from "../../redux/types";
import {RootState} from "../../redux/store";
import * as algoServices from "../service/algo";
import {getAuthorizationHeader} from "../utils";
import {AlgoProcessRequest} from "../payload/algo";

export const getAlgoProcessAction = (id: number, callbacks:CallbackServiceProp) => {
    const {success, fail} = callbacks;

    return async (dispatch: Dispatch<IAuthAction>, getState: () => RootState) => {
        algoServices.getAlgoProcessService(
            id,
            getAuthorizationHeader(getState)
        ).then(success, fail);
    }
}

export const patchAlgoProcessAction = (id:number, data:AlgoProcessRequest, callbacks:CallbackServiceProp) => {
    const {success, fail} = callbacks;

    return async (dispatch: Dispatch<IAuthAction>, getState: () => RootState) => {
        algoServices.patchAlgoProcessService(
            id,
            data,
            getAuthorizationHeader(getState)
        ).then(success, fail);
    }
}


