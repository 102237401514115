import React, {ReactElement, ReactNode, useEffect, useState} from "react";

import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";

import {
    Box,
    CircularProgress, IconButton,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {CheckCircle, Edit, HourglassTop, LockClock, Refresh, Save} from "@mui/icons-material";

import {FailCallbackType, SuccessCallbackType} from "../api/types";
import {ProcessFilePayload, VideoPayload} from "../api/payload/core";
import {AlgoProcessPayload, AlgoProcessRequest} from "../api/payload/algo";
import {getVideoAction} from "../api/action/core";
import * as algoActions from "../api/action/algo";

import {VideoStatusId} from "../components/VideoStatus";
import {a11yProps, TabPanel} from "../components/TabPanel";
import {ProcessFiles} from "../components/ProcessFiles";
import {ToeGrid} from "../components/ToeGrid";
import {VideoInfo} from "../components/VideoInfo";
import {useSnackbar} from "notistack";
import {AxiosResponse} from "axios";
import {AlgoProcessAttributeForm} from "../components/AlgoProcessAttributeForm";
import {AlgoResults} from "../components/AlgoResults";

type VideoDetailParams = {
    id: string;
};

type TabStatus = {
    label: ReactNode,
    disabled?: boolean
    icon: string | ReactElement;
    iconPosition?: 'top' | 'bottom' | 'start' | 'end';
}

type TabStatusMapping = {
    [name: string]: TabStatus;
}


const fileTypePriority = (filetype:string):number => {
    switch (filetype) {
        case 'DMPE':
            return 1;
        case 'BVH':
            return 2
        case 'FBX':
            return 3
        case 'JSON':
            return 3
        case 'LOG':
            return 20

    }
    return 10;
}

export const VideoDetail = () => {
    const [video, setVideo] = useState<VideoPayload>();
    const [algoProcess, setAlgoProcess] = useState<AlgoProcessPayload>();
    const [activeTab, setActiveTab] = useState<number>(0);
    const [processInTab, setProcessInTab] = useState<boolean>(false);
    const [tabStatuses, setTabStatuses] = useState<TabStatusMapping>({
        deepmotion: {label: "DeepMotion Files", icon:<LockClock />, iconPosition:"end"},
        toe: {label: "Toe Data", disabled:true, icon:<LockClock />, iconPosition:"end"},
        calibration: {label: "Calibration", disabled:true, icon:<LockClock />, iconPosition:"end"},
        algoParams: {label: "Algorithm Parameters", disabled:true, icon:<LockClock />, iconPosition:"end"},
        results: {label: "Results", disabled:true, icon:<LockClock />, iconPosition:"end"},
    });

    let {id} = useParams<VideoDetailParams>();

    const dispatch = useDispatch();

    const {enqueueSnackbar} = useSnackbar();

    const refreshVideoData = () => {
        setVideo(undefined);
        let id_number = id ? parseInt(id): 0;
        dispatch(getVideoAction(id_number, {success: onTableDataGet, fail: onRequestFail}));
    }

    useEffect(()=>{
        refreshVideoData();
    }, [id, dispatch])

    useEffect(()=>{
        if(video && video.status){
            let diff:TabStatusMapping = tabStatuses;
            if(video.status >= VideoStatusId.DEEPMOTION_SUCCESS) {
                diff = {
                    ...diff,
                    deepmotion: {...diff.deepmotion, icon: <CheckCircle color={"success"} />}
                };
            }

            if(video.status >= VideoStatusId.ALGO_INPUT_REQUIRED) {
                diff = {
                    ...diff,
                    toe: {...diff.toe, icon: <Edit color={"warning"} />, disabled: false},
                    calibration: {...diff.calibration, icon: <Edit color={"warning"} />, disabled: false},
                    algoParams: {...diff.algoParams, icon: <Edit color={"warning"} />, disabled: false}
                };
                dispatch(algoActions.getAlgoProcessAction(video.id, {success: onAlgoProcessGet}));
            }

            if(video.status >= VideoStatusId.ALGO_FAIL) {
                diff = {
                    ...diff,
                    toe: {...diff.toe, icon: <CheckCircle color={"success"} />, disabled: false},
                    calibration: {...diff.calibration, icon: <CheckCircle color={"success"} />, disabled: false},
                    algoParams: {...diff.algoParams, icon: <CheckCircle color={"success"} />, disabled: false},
                    results: {...diff.results, icon: <CheckCircle color={"success"} />, disabled: false}
                };
            }
            setTabStatuses(diff);
        }
    }, [video?.status])

    const onTableDataGet:SuccessCallbackType = response => {
        let {data} = response;
        const sortedProcessFiles = data.process_files.sort((a:ProcessFilePayload, b: ProcessFilePayload) => {
            return fileTypePriority(a.filetype) - fileTypePriority(b.filetype);
        })
        data.process_files = sortedProcessFiles;
        setVideo(data);
    }

    const onRequestFail:FailCallbackType = reason => {

    }

    const onAlgoProcessGet:SuccessCallbackType = response => {
        let {data} = response;
        setAlgoProcess(data);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        if(!processInTab)
            setActiveTab(newValue);
    };

    const onAlgoProcessAttributeChange = (tabKey:string) => {
        return (status:string, delay:number) => {
            setTabStatuses({
                ...tabStatuses,
                [tabKey]: {...tabStatuses[tabKey], icon: <HourglassTop color={"warning"} />}
            });
            setProcessInTab(true);
        }
    }

    const onAlgoProcessAttributeSave = (key: 'toe_data' | 'calibration', tabKey:string) => {
        return (csv:string) => {
            setTabStatuses({
                ...tabStatuses,
                [tabKey]: {...tabStatuses[tabKey], icon: <CircularProgress color={"warning"} size={"1em"} />}
            });
            dispatch(algoActions.patchAlgoProcessAction(
                video?.id || 0,
                {[key]: csv},
                {success: handleAlgoProcessAttributeSave(tabKey)}
            ));

        }
    }

    const handleAlgoProcessAttributeSave = (tabKey: string) => {
        return (response:AxiosResponse<AlgoProcessPayload>) => {
            setProcessInTab(false);
            setTabStatuses({
                ...tabStatuses,
                [tabKey]: {...tabStatuses[tabKey], icon: <Save color={"success"} />}
            });
            onAlgoProcessGet(response);
            if(tabKey === "toe"){
                enqueueSnackbar("Toe Data updated successfully", {variant: "success", autoHideDuration: 1500});
            } else if (tabKey === "calibration"){
                enqueueSnackbar("Calibration Data updated successfully", {variant: "success", autoHideDuration: 1500});
            }
        }
    }

    const onAlgoParamsSave = ({leg_length, distance, fps}:AlgoProcessRequest) => {
        setTabStatuses({
            ...tabStatuses,
            algoParams: {...tabStatuses.algoParams, icon: <CircularProgress color={"warning"} size={"1em"} />}
        });

        const data: AlgoProcessRequest = {
            leg_length: leg_length,
            distance: distance,
            fps: fps,
        };

        dispatch(algoActions.patchAlgoProcessAction(
            video?.id || 0,
            data,
            {success: handleAlgoParamsSave}
        ));
    }

    const handleAlgoParamsSave = (response:AxiosResponse<AlgoProcessPayload>) => {
        onAlgoProcessGet(response);
        setTabStatuses({
            ...tabStatuses,
            algoParams: {...tabStatuses.algoParams, icon: <Save color={"success"} />}
        });
        enqueueSnackbar(
            "Additional algorithm parameters successfully updated.",
            {variant: "success", autoHideDuration: 1500}
        );
    }

    const onSchedule= () => {
        if(algoProcess){
            if(["NEW", "SUCCESS", "FAIL"].includes(algoProcess?.status)){
                dispatch(algoActions.patchAlgoProcessAction(
                    video?.id || 0,
                    {status: "DATA_ENTERED"},
                    {success: handleScheduleSuccess}
                ));
            } else {
                enqueueSnackbar(
                    "Please wait until scheduled task to be completed.",
                    {variant: "warning", autoHideDuration: 10000}
                );
            }
        }

    }

    const handleScheduleSuccess = (response:AxiosResponse<AlgoProcessPayload>) => {
        enqueueSnackbar(
            "Video has been scheduled for algorithm processing. Please check again.",
            {variant: "success", autoHideDuration: 10000}
        );
    }

    if(!video){
        return (
            <Box sx={{m: 2, textAlign: "center"}}>
                <CircularProgress />
            </Box>
        )
    }

    return(
        <>
            <Box sx={{m: 2}}>
                <Typography variant={"h4"}>
                    Video Detail: {video.filename} <IconButton onClick={refreshVideoData}><Refresh /></IconButton>
                </Typography>
            </Box>

            <VideoInfo video={video} />

            <Box sx={{m: 2}}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs example">
                        <Tab {...tabStatuses.deepmotion} {...a11yProps(0)}></Tab>
                        <Tab {...tabStatuses.toe} {...a11yProps(1)} />
                        <Tab {...tabStatuses.calibration} {...a11yProps(2)} />
                        <Tab {...tabStatuses.algoParams} {...a11yProps(3)} />
                        <Tab {...tabStatuses.results} {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={activeTab} index={0}>
                    <ProcessFiles video={video} />
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    <ToeGrid
                        defaultRowCount={20}
                        initial={algoProcess?.toe_data || ""}
                        onChange={onAlgoProcessAttributeChange("toe")}
                        onSave={onAlgoProcessAttributeSave("toe_data", "toe")}
                        readonly={!["NEW", "SUCCESS", "FAIL"].includes(algoProcess?.status || "NEW")}
                    />
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    <ToeGrid
                        defaultRowCount={2}
                        initial={algoProcess?.calibration || ""}
                        onChange={onAlgoProcessAttributeChange("calibration")}
                        onSave={onAlgoProcessAttributeSave("calibration", "calibration")}
                        readonly={!["NEW", "SUCCESS", "FAIL"].includes(algoProcess?.status || "NEW")}
                    />
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                    {
                        algoProcess &&
                        <AlgoProcessAttributeForm
                            distance={algoProcess.distance}
                            fps={algoProcess.fps}
                            legLength={algoProcess.leg_length}
                            onSave={onAlgoParamsSave}
                            onSchedule={onSchedule}
                            scheduleButtonHidden={!["NEW", "SUCCESS", "FAIL"].includes(algoProcess?.status || "NEW")}
                        />
                    }

                </TabPanel>
                <TabPanel value={activeTab} index={4}>
                    {
                        algoProcess &&
                        <AlgoResults
                            params={algoProcess.params}
                            avg={algoProcess.avg}
                            curves={algoProcess.curves}
                            message={algoProcess.message} />
                    }

                </TabPanel>


            </Box>
        </>
    )
}
