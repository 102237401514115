import React, {useEffect, useState} from "react";
import {
    DataSheetGrid,
    textColumn,
    keyColumn, Column,
} from 'react-datasheet-grid'

import 'react-datasheet-grid/dist/style.css'
import {Box, CircularProgress} from "@mui/material";

export interface ResultData {
    name?: string;
    event_labels?: string;
    time_5m?: string;
    time_10m?: string;
    peak_velocity?: string;
    gct?: string;
    at?: string;
    di?: string;
    rel_step_length?: string;
    rel_hd?: string;
    rel_td_hh?: string;
    rel_to_hh?: string;
    step_freqency?: string;
    td_velocity?: string;
    to_velocity?: string;
    step_velocity?: string;
    trunk_angle?: string;
    rel_td_dist?: string;
    rel_to_dist?: string;
    early_flexion?: string;
    late_flexion?: string;
    early_extension?: string;
    late_extension?: string;
    flexion?: string;
    extension?: string;
    full?: string;
    rel_peak_velocity?: string;
    step_length?: string;
    hd?: string;
    td_hh?: string;
    to_hh?: string;
    td_dist?: string;
    to_dist?: string;
}

const getColumns = (disabled:boolean=false):Column[] => [
    { ...keyColumn("name", textColumn), title: "Name", disabled: disabled},
    { ...keyColumn("event_labels", textColumn), title: "Event Labels", disabled: disabled},
    { ...keyColumn("time_5m", textColumn), title: "Time_5m", disabled: disabled},
    { ...keyColumn("time_10m", textColumn), title: "Time_10m", disabled: disabled},
    { ...keyColumn("peak_velocity", textColumn), title: "Peak_Velocity", disabled: disabled},
    { ...keyColumn("gct", textColumn), title: "GCT", disabled: disabled},
    { ...keyColumn("at", textColumn), title: "AT", disabled: disabled},
    { ...keyColumn("di", textColumn), title: "Di", disabled: disabled},
    { ...keyColumn("rel_step_length", textColumn), title: "Rel Step Length", disabled: disabled},
    { ...keyColumn("rel_hd", textColumn), title: "Rel HD", disabled: disabled},
    { ...keyColumn("rel_td_hh", textColumn), title: "Rel TD HH", disabled: disabled},
    { ...keyColumn("rel_to_hh", textColumn), title: "Rel TO HH", disabled: disabled},
    { ...keyColumn("step_freqency", textColumn), title: "Step Freqency", disabled: disabled},
    { ...keyColumn("td_velocity", textColumn), title: "TD Velocity", disabled: disabled},
    { ...keyColumn("to_velocity", textColumn), title: "TO Velocity", disabled: disabled},
    { ...keyColumn("step_velocity", textColumn), title: "Step Velocity", disabled: disabled},
    { ...keyColumn("trunk_angle", textColumn), title: "Trunk Angle", disabled: disabled},
    { ...keyColumn("rel_td_dist", textColumn), title: "Rel TD Dist", disabled: disabled},
    { ...keyColumn("rel_to_dist", textColumn), title: "Rel TO Dist", disabled: disabled},
    { ...keyColumn("early_flexion", textColumn), title: "Early Flexion", disabled: disabled},
    { ...keyColumn("late_flexion", textColumn), title: "Late Flexion", disabled: disabled},
    { ...keyColumn("early_extension", textColumn), title: "Early Extension", disabled: disabled},
    { ...keyColumn("late_extension", textColumn), title: "Late Extension", disabled: disabled},
    { ...keyColumn("flexion", textColumn), title: "Flexion", disabled: disabled},
    { ...keyColumn("extension", textColumn), title: "Extension", disabled: disabled},
    { ...keyColumn("full", textColumn), title: "Full", disabled: disabled},
    { ...keyColumn("rel_peak_velocity", textColumn), title: "Rel Peak_Velocity", disabled: disabled},
    { ...keyColumn("step_length", textColumn), title: "Step Length", disabled: disabled},
    { ...keyColumn("hd", textColumn), title: "HD", disabled: disabled},
    { ...keyColumn("td_hh", textColumn), title: "TD HH", disabled: disabled},
    { ...keyColumn("to_hh", textColumn), title: "TO HH", disabled: disabled},
    { ...keyColumn("td_dist", textColumn), title: "TD Dist", disabled: disabled},
    { ...keyColumn("to_dist", textColumn), title: "TO Dist", disabled: disabled},
];


export interface ResultGridProps {
    initial:string;
}

export const ResultGrid = ({initial}:ResultGridProps) => {
    const [data, setData] = useState<ResultData[]>([]);

    const columns = getColumns(true);

    useEffect(()=>{
        let resultData:ResultData[] = [];
        initial.split('\n').forEach((line, idx)=>{
            if(idx > 0){
                let resultDataLine:ResultData = {};
                line.split(",").forEach((item, colIdx)=>{
                    let column = columns[colIdx];

                    (resultDataLine as any)[column.id || ""] = item.trim();
                })
                resultData.push(resultDataLine);
            }
        })
        setData(resultData);

    }, [])


    if(data === []){
        return (
            <CircularProgress />
        )
    }

    return (
        <DataSheetGrid
            value={data}
            columns={columns}
            onChange={setData}
            key={Math.random()}
        />
    );

}
