import React from "react";
import {UploadVideo} from "../UploadVideo";
import {Route, Routes} from "react-router-dom";
import {VideoList} from "../VideoList";
import {VideoDetail} from "../VideoDetail";
import {RedirectToRoot} from "../../components/RedirectToRoot";


export const Private = () => {

    return(
        <Routes>
            <Route path={"/"} element={<UploadVideo />} />
            <Route path={"/videos/"}>
                <Route index element={<VideoList />} />
                <Route path={":id"} element={<VideoDetail />} />
            </Route>
            <Route path={"*"} element={<RedirectToRoot />} />
        </Routes>
    )
}