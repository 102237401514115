import {Chip, Grid, Table, TableBody, TableCell, Typography} from "@mui/material";
import {TableRowZebra} from "./TableRowZebra";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import prettyBytes from "pretty-bytes";
import TagRoundedIcon from "@mui/icons-material/TagRounded";
import AspectRatioRoundedIcon from "@mui/icons-material/AspectRatioRounded";
import SlowMotionVideoRoundedIcon from "@mui/icons-material/SlowMotionVideoRounded";
import TimelapseRoundedIcon from "@mui/icons-material/TimelapseRounded";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import VideoFileRoundedIcon from "@mui/icons-material/VideoFileRounded";
import React from "react";
import {VideoPayload} from "../api/payload/core";
import {VideoStatus} from "./VideoStatus";


interface VideoInfoProps {
    video: VideoPayload
}

export const VideoInfo = ({video}:VideoInfoProps) => {
    return(
        <Grid container sx={{m: 2}}>
            <Grid item lg={6}>
                <Typography variant={"h5"}>
                    Video Properties
                </Typography>
                <Table size={"small"}>
                    <TableBody>
                        <TableRowZebra>
                            <TableCell sx={{whiteSpace: "nowrap"}} variant={"head"}>File Name</TableCell>
                            <TableCell sx={{width: "100%"}}><a href={video.file.url} target={"_blank"} rel="noreferrer">{video.filename}</a></TableCell>
                        </TableRowZebra>
                        <TableRowZebra>
                            <TableCell sx={{whiteSpace: "nowrap"}} variant={"head"}>File Size</TableCell>
                            <TableCell sx={{width: "100%"}}>
                                <Chip avatar={<SaveRoundedIcon />} label={prettyBytes(video.filesize)} />
                                <Chip sx={{ml: 2}} avatar={<TagRoundedIcon />} label={`MD5: ${video.md5sum}`} />
                                <Chip sx={{ml: 2}} avatar={<TagRoundedIcon />} label={`SHA1: ${video.sha1sum}`} />
                            </TableCell>
                        </TableRowZebra>
                        <TableRowZebra>
                            <TableCell sx={{whiteSpace: "nowrap"}} variant={"head"}>Video Properties</TableCell>
                            <TableCell sx={{width: "100%"}}>
                                <Chip avatar={<AspectRatioRoundedIcon />}  label={`W: ${video.width || "Nan"} / H: ${video.height || "Nan"}`} />
                                <Chip sx={{ml: 2}} avatar={<SlowMotionVideoRoundedIcon />}  label={`${video.fps || "Nan"} fps`} />
                                <Chip sx={{ml: 2}} avatar={<TimelapseRoundedIcon />}  label={`${video.duration || "Nan"} s`} />
                                <Chip sx={{ml: 2}} avatar={<ImageRoundedIcon />}  label={`${video.frames || "Nan"} frames`} />
                                <Chip sx={{ml: 2}} avatar={<VideoFileRoundedIcon />}  label={`${video.codec || "Nan"}`} />

                            </TableCell>
                        </TableRowZebra>
                        <TableRowZebra>
                            <TableCell sx={{whiteSpace: "nowrap"}} variant={"head"}>Status</TableCell>
                            <TableCell sx={{width: "100%"}}><VideoStatus status={video.status}/></TableCell>
                        </TableRowZebra>
                    </TableBody>
                </Table>
            </Grid>
            <Grid item lg={6} sx={{textAlign: "center", mt: {md: 5, lg:0}}}>
                <video height={300} controls >
                    <source src={video.file.url} />
                </video>
            </Grid>
        </Grid>
    )
}