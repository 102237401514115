import {Chip, IconButton, LinearProgress, Stack, TableCell} from "@mui/material";
import {SlowMotionVideo, Replay, RemoveCircle} from "@mui/icons-material";
import prettyBytes from "pretty-bytes";
import React, {useEffect, useState} from "react";
import {FailCallbackType, SuccessCallbackType} from "../api/types";
import {TableRowZebra} from "./TableRowZebra";
import {uploadVideoAction} from "../api/action/core";
import {useDispatch} from "react-redux";


export enum UploadStatus {
    ENQUEUED = "Enqueued",
    READING = "Reading",
    UPLOADING = "Uploading",
    SUCCESS = "Uploaded",
    FAIL = "Failed",
}

export type UploadFileType = {
    file: File,
    status: UploadStatus,
    loaded?: number,
    size?: number,
    progress?: number,
    errors?: string[]
}

type ColorType = 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';


const chipColorByStatus = (status: UploadStatus):ColorType => {
    switch(status) {
        case UploadStatus.ENQUEUED:
            return "secondary";
        case UploadStatus.READING:
            return "warning";
        case UploadStatus.UPLOADING:
            return "info";
        case UploadStatus.SUCCESS:
            return "success";
        case UploadStatus.FAIL:
            return "error";
        default:
            return "secondary";
    }
}

export const FileUpload = (props:{file:File, removeCallback?:(filename:string)=>void}) => {
    const [status, setStatus] = useState<UploadStatus>(UploadStatus.ENQUEUED);
    const [loaded, setLoaded] = useState<number>(0);
    const [progress, setProgress] = useState<number>(0);
    const [errors, setErrors] = useState<string[]>([])

    const dispatch = useDispatch();
    const {file, removeCallback} = props;

    useEffect((): void => {
        switch (status){
            case UploadStatus.ENQUEUED:
                setProgress(0);
                setStatus(UploadStatus.READING);
                break;
            case UploadStatus.READING:
                const reader = new FileReader();

                reader.onabort = () => {
                    setErrors(["File read is aborted!"]);
                    setStatus(UploadStatus.FAIL);
                };
                reader.onerror = () => {
                    setErrors(["Cannot read the file!"]);
                    setStatus(UploadStatus.FAIL);
                };
                reader.onload = () => {
                    // Do whatever you want with the file contents
                    setStatus(UploadStatus.UPLOADING);
                };
                reader.readAsArrayBuffer(file);
                break;
            case UploadStatus.UPLOADING:
                dispatch(uploadVideoAction({file: file}, {progress: onProgress, success: onSuccess, fail:onFail}));
                break;
        }

    }, [file, status, dispatch]);

    const onProgress = (event: ProgressEvent) => {
        setLoaded(event.loaded);
        setProgress(100 * (event.loaded / event.total));
    }

    const onSuccess:SuccessCallbackType = (response) => {
        //let fileInfo = response.data;
        setStatus(UploadStatus.SUCCESS);
    };

    const onFail:FailCallbackType = (reason) => {
        let errorDetail = [];
        if(reason.response !== undefined){
            errorDetail = reason.response.data;
        } else {
            errorDetail = ["Connection Error! Please check your connectivity and try again."]
        }
        setErrors(errorDetail);
        setStatus(UploadStatus.FAIL);
    };

    const tryAgain = () => {
        setErrors([]);
        setStatus(UploadStatus.ENQUEUED);
    }

    const remove = () => {
        if(removeCallback !== undefined){
            removeCallback(file.name);
        }
    }

    return(
        <TableRowZebra
            key={file.name}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableCell sx={{whiteSpace: "nowrap"}}>
                <Chip color={chipColorByStatus(status)} avatar={<SlowMotionVideo color={"inherit"}/>} label={file.name} />
            </TableCell>
            <TableCell>

                {
                    errors === undefined || errors.length < 1 ?
                        <LinearProgress color={chipColorByStatus(status)} variant="determinate" value={progress || 0} />
                        :
                        <Stack direction={"column"}>
                            {errors.map((error, index) => <Chip key={index} sx={{borderColor: "#ba000d !important", border: "1px", borderStyle:"solid"}} label={error}/>)}
                        </Stack>

                }
            </TableCell>
            <TableCell sx={{whiteSpace: "nowrap"}}>
                {prettyBytes(loaded || 0)} / {prettyBytes(file.size || 0)}
            </TableCell>
            <TableCell sx={{whiteSpace: "nowrap"}}>
                <Chip color={chipColorByStatus(status)} label={status} />
            </TableCell>
            <TableCell sx={{whiteSpace: "nowrap"}}>
                <IconButton disabled={status !== UploadStatus.FAIL} onClick={tryAgain}>
                    <Replay />
                </IconButton>
                <IconButton disabled={status !== UploadStatus.SUCCESS && status !== UploadStatus.FAIL} onClick={remove}>
                    <RemoveCircle />
                </IconButton>
            </TableCell>
        </TableRowZebra>
    );
}