import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export const RedirectToRoot = (props:{root?:string}) => {
    const navigate = useNavigate();
    const { root="/" } = props;

    useEffect(()=>{
        navigate(root);
    }, [root, navigate])

    return(<></>);
}