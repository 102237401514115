import {DataGrid, GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import React from "react";
import {Chip} from "@mui/material";
import Moment from "react-moment";
import {VideoPayload} from "../api/payload/core";

interface ProcessFilesProps {
    video: VideoPayload
}

const processFilesColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Video File Name',
        flex: 10,
        editable: false,
        renderCell: (params) => (<a href={`${params.row.file}`} target={"_blank"} rel="noreferrer">{params.value}</a>)
    },
    {
        field: 'filetype',
        headerName: 'File Type',
        flex: 1,
        editable: false,
        renderCell: (params) => (<Chip label={params.value} />)
    },
    {
        field: 'created_at',
        headerName: 'Created At',
        type: "dateTime",
        flex: 3,
        editable: false,
        renderCell: (params:GridRenderCellParams<Date>) => (<Moment format={"L LTS"} date={params.value} />),
    },
    {
        field: 'source',
        headerName: 'Source',
        flex: 5,
        editable: false,
    },
];

export const ProcessFiles = ({video}:ProcessFilesProps) => {
    return (
        <div style={{ height: "500px", minHeight: "500px", width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        rows={video.process_files}
                        columns={processFilesColumns}
                        pageSize={50}
                        disableSelectionOnClick
                        sx={{borderRadius: "0"}}

                    />
                </div>
            </div>
        </div>
    )
}
