import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {URL_API_ALGO_PROCESS} from "../urls";
import {AlgoProcessPayload, AlgoProcessRequest} from "../payload/algo";
import {HttpHeaders} from "../types";

export const getAlgoProcessService = (id:number, extraHeaders:HttpHeaders):Promise<AxiosResponse<AlgoProcessPayload>> => {
    const url = URL_API_ALGO_PROCESS({id: id});

    const config:AxiosRequestConfig = {
        headers: {
            ...extraHeaders
        },
    }

    return new Promise(((resolve, reject) => {
        axios.get(url, config)
            .then(
                (response: AxiosResponse) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                }
            );
    }));
}

export const patchAlgoProcessService = (id:number, data:AlgoProcessRequest, extraHeaders:HttpHeaders):Promise<AxiosResponse<AlgoProcessPayload>> => {
    const url = URL_API_ALGO_PROCESS({id: id});

    const config:AxiosRequestConfig = {
        headers: {
            ...extraHeaders
        },
    }

    return new Promise(((resolve, reject) => {
        axios.patch(url, data, config)
            .then(
                (response: AxiosResponse) => {
                    resolve(response);
                }, (error) => {
                    reject(error);
                }
            );
    }));
}