import React, {useCallback, useMemo, useState} from "react";
import {useDropzone} from 'react-dropzone'
import {
    Box,
    Paper,
    Table, TableBody, TableCell,
    TableContainer,
    TableHead, TableRow,
    Typography
} from "@mui/material";

import {Add} from "@mui/icons-material";

import "../style/plain/dropzone.css"
import {useSnackbar} from "notistack";
import {FileUpload} from "../components/FileUpload";


type FileMap = {
    [key: string]: File
}


export const UploadVideo = () => {
    const [files, setFiles] = useState<FileMap>({});
    const {enqueueSnackbar} = useSnackbar();

    const onDrop = useCallback(acceptedFiles => {
        acceptedFiles.forEach((file: File) => {
            let fileExists = file.name in files;

            if (!fileExists){
                setFiles(prev => ({
                    ...prev,
                    [file.name]:file
                }));
            } else {
                enqueueSnackbar(`${file.name} is already enqueued.`, {variant: "error"});
            }
        });
    }, [files, enqueueSnackbar]);

    const removeFile = useCallback((filename:string) => {
        if (filename in files){
            const { [filename]: tmp, ...rest } = files;
            setFiles(rest);
        }
    }, [files])

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: 'video/*',
        onDrop: onDrop
    });

    const dropzoneSx = useMemo(() => {
        if(isDragAccept)
            return {borderColor: "success.dark", backgroundColor: "success.light", color: "success.contrastText"};
        if(isDragReject)
            return {borderColor: "error.dark", backgroundColor: "error.light", color: "error.contrastText"};
        if(isFocused)
            return {borderColor: "info.dark", backgroundColor: "info.light", color: "info.contrastText"};

        return {};
    }, [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return (
        <>
            <Box sx={{m: 2}}>
                <Typography variant={"h4"}>
                    Upload Videos
                </Typography>
            </Box>
            <Box sx={{m: 2}} className={"container"}>
                <Box className={"dropzone"} {...getRootProps()} sx={dropzoneSx}>
                    <input {...getInputProps()} />
                    <Typography variant={"h5"} sx={{fontWeight: "bolder"}}>
                        <Add fontSize={"small"}/>
                        Drag 'n' drop some files here, or click to select files
                    </Typography>
                    <Typography variant={"h6"} sx={{fontWeight: "bolder"}}>
                        { isDragReject ? "Only video files are allowed!":"\u00A0" }
                    </Typography>
                </Box>
            </Box>
            <Box sx={{m: 2}}>
                <TableContainer component={Paper} sx={{maxHeight: 450}}>
                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{whiteSpace: "nowrap"}}>File</TableCell>
                                <TableCell sx={{width: "100%"}}>Progress</TableCell>
                                <TableCell sx={{whiteSpace: "nowrap"}}>Size</TableCell>
                                <TableCell sx={{whiteSpace: "nowrap"}}>Status</TableCell>
                                <TableCell sx={{whiteSpace: "nowrap"}}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.values(files).map((uploadFile:File) => (
                                <FileUpload key={uploadFile.name} file={uploadFile} removeCallback={removeFile} />
                            ))}
                            {
                                Object.values(files).length === 0 &&
                                    <TableRow>
                                        <TableCell colSpan={5} sx={{textAlign: "center"}}>
                                            <h3>Please add files to upload!</h3>
                                        </TableCell>
                                    </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </>
    )
}
